import React from 'react'

import playstoreIcon from '../../assets/playstoreIcon.svg';
import appstoreIcon from '../../assets/appstoreIcon.svg';

const playstoreLink = "https://play.google.com/store/apps/details?id=com.momatu.android"
const appstoreLink = "https://apps.apple.com/gb/app/momatu/id1313564080"

export const PlaystoreButton = () => {
    return (
        <a target="_blank" rel="noopener noreferrer" href={playstoreLink}>
            <img src={playstoreIcon} alt="Playstore" className="w-32 sm:w-32 md:w-48 lg:w-56"/>
        </a>
    )
}

export const AppstoreButton = () => {
    return (
        <a target="_blank" rel="noopener noreferrer" href={appstoreLink}>
            <img src={appstoreIcon} alt="Appstore" className="w-32 sm:w-32 md:w-48 lg:w-56"/>
        </a>
    )
}

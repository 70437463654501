import React from 'react';
import EmailAsset from '../../assets/email.svg';

const EmailVerified = ({...otherProps}) => {
    return (
        <img
            alt={"Email Verified"}
            src={EmailAsset}
            {...otherProps}
        />
    );
};

export default EmailVerified;

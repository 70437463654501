import React from 'react';
import {PageDescription, PageTitle} from "./Page";

const ActionPage = ({image, title, subtitle}) => {
    return (
        <div className="h-full flex justify-center items-center flex-col">
            {image}
            <div className="flex justify-center items-center flex-col mt-4">
                <PageTitle text={title} customStyle={'mb-4'}/>
                <PageDescription text={subtitle} customStyle="w-11/12 md:w-4/6"/>
            </div>
        </div>
    )
}

export default ActionPage

import React from 'react';

const Form = ({children, onSubmit}) => {
    return (
        <form className="flex justify-center items-center flex-col w-full md:w-104"
              action="/"
              method="POST"
              onSubmit={onSubmit}
        >
            {children}
        </form>
    );
};

export default Form;

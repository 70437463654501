import React from 'react'
import LogoAsset from '../../assets/logo.svg';

const Logo = ({...otherProps}) => {
    return (
        <img alt="logo" src={LogoAsset} {...otherProps}/>
    )
}

export default Logo

export const LandingPageLogo = () => {
    return (
        <Logo className="w-32 md:w-48 lg:w-56"/>
    )
}

export const PageLogo = () => {
    return (
        <Logo className="absolute left-11 top-8 hidden w-18 sm:inline-block md:inline-block"/>
    )
}

import React from 'react';
import FailureAsset from "../../assets/failure.svg";

const Failure = ({customStyle}) => {
    return (
        <img
            alt="Failure"
            src={FailureAsset}
            className={`w-32 md:w-52 ${customStyle}`}
        />
    );
}

export default Failure;

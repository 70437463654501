import React, {useEffect, useState} from 'react'
import ActionPage from "../../components/pages/ActionPage";
import Failure from "../../components/images/Failure";
import ProgressOverlay from "../../components/pages/ProgressOverlay";
import {CreateVideoRoomWherebyHandler} from "../../services/API";
import {useParams, useLocation, useHistory} from "react-router-dom";
import {isIOS} from "react-device-detect";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const createWherebyUrl = (url, browser) => `${url}&leaveButton=${browser ? 'on' : 'off'}`

const VideoRoomPage = () => {
    const history = useHistory()
    const query = useQuery()
    const {homeId} = useParams()
    const [loadingState, setLoadingState] = useState()
    const [roomUrl, setRoomUrl] = useState()

    useEffect(() => {
        async function createVideoRoom() {
            const auth = query.get('auth')
            const browser = query.has('browser')
            history.replace({search: ""})

            setLoadingState('loading')
            const result = await CreateVideoRoomWherebyHandler(auth, homeId, browser)
            if (result.status === 200) setRoomUrl(createWherebyUrl(result.data.roomUrl, browser))
            setLoadingState(!result.errorStatus ? 'success' : 'failure')
        }

        createVideoRoom()
    }, [homeId])

    const SuccessfulScreen = () =>
        <iframe
            src={`${roomUrl}`}
            className={`${isIOS ? 'min-height-100vh-webkit' : 'min-height-100vh'} w-full bg-gray-900 bg-opacity-75`}
            allow="camera; microphone; fullscreen; speaker; display-capture"
        />

    const FailureScreen = () =>
        <ActionPage
            image={<Failure customStyle={'mr-8 md:mr-20'}/>}
            title={"We experience some troubles with your home video room"}
            subtitle={"Please try again"}
        />

    const showProgress = loadingState === 'loading'
    const showSuccess = ['success'].includes(loadingState)
    const showFailure = ['failure'].includes(loadingState)

    return (
        <>
            {showProgress && <ProgressOverlay/>}
            {showSuccess && SuccessfulScreen()}
            {showFailure && FailureScreen()}
        </>
    )
}

export default VideoRoomPage

import Layout from './components/pages/Layout';
import LandingPage from './screens/Landing';
import ForgotPasswordPage from './screens/ForgotPassword';
import InvitationsPage from './screens/Invitations';
import ResetPasswordPage from './screens/ResetPassword';
import VerifyEmailPage from './screens/VerifyEmail';
import VideoRoomWherebyPage from "./screens/VideoRoomWhereby";
import AppEnvironmentSelectPage from "./screens/AppEnvironmentSelect";
import {Route, Switch} from 'react-router-dom';

function App() {
    return (
        <div>
            <Layout>
                <Switch>
                    <Route path="/" exact component={LandingPage}/>
                    <Route path="/forgot" exact component={ForgotPasswordPage}/>
                    <Route path="/reset-password/:token" exact component={ResetPasswordPage}/>
                    <Route path="/verify-email/:token" exact component={VerifyEmailPage}/>
                    <Route path="/invitations/:token" exact component={InvitationsPage}/>
                    <Route path="/homes/:homeId/rooms/whereby" exact component={VideoRoomWherebyPage}/>
                    <Route exact path="/terms-of-use" render={() => {window.location.href="terms-of-use.html"}} />
                    <Route exact path="/privacy-policy" render={() => {window.location.href="privacy-policy.html"}} />
                    <Route path="/env" exact component={AppEnvironmentSelectPage}/>
                </Switch>
            </Layout>
        </div>
    );
}

export default App;

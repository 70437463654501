import React from 'react';
import Aux from '../hoc/Aux';

const Layout = (props) => (
    <Aux>
        {props.children}
    </Aux>
);

export default Layout;

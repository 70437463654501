import React, {useState} from 'react'
import {useForm} from "react-hook-form";
import * as yup from 'yup';
import {yupResolver} from "@hookform/resolvers/yup";
import {useParams} from 'react-router-dom';
import {ResetPasswordHandler} from "../../services/API";

import checkmarkGreyIcon from "../../assets/checkmarkGrey.svg";
import checkmarkGreenIcon from "../../assets/checkmarkGreen.svg";

import {PageLogo} from "../../components/images/Logo";
import Success from "../../components/images/Success";
import Failure from "../../components/images/Failure";
import {PageLayout, PageTitle} from "../../components/pages/Page";

import Form from "../../components/forms/Form";
import {InputError, InputPassword} from "../../components/forms/Input";

import Button from "../../components/buttons/Button";
import ProgressOverlay from "../../components/pages/ProgressOverlay";
import ActionPage from "../../components/pages/ActionPage";

const checkmarkAsset = enabled => enabled ? checkmarkGreenIcon : checkmarkGreyIcon;

const ResetPasswordPage = () => {
    const token = useParams().token;
    const regex = /^(?=.*[A-Za-z])(?=.*\d)|(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    const schema = yup.object().shape({
        password: yup.string()
            .required('Please provide password')
            .min(8, 'Password needs to be at least 8 characters long')
            .matches(regex, 'Password needs to contain a symbol or number'),
        passwordConfirm: yup.string()
            .required('Please provide password confirmation')
            .oneOf([yup.ref('password')], 'Passwords do not match')
    })

    const [loadingState, setLoadingState] = useState('pending')
    const {register, handleSubmit, formState: {errors}, watch} = useForm({
        mode: "onSubmit",
        resolver: yupResolver(schema),
    });

    const onSubmit = async ({password}) => {
        setLoadingState('loading')
        const result = await ResetPasswordHandler(token, password)
        setLoadingState(!result.errorStatus ? 'success' : 'failure')
    }

    const SuccessfulScreen = () =>
        <ActionPage
            image={<Success customStyle={'ml-16 md:ml-24'}/>}
            title={"Password changed successfully"}
            subtitle={"Now you can log back into your mobile app"}
        />

    const FailureScreen = () =>
        <ActionPage
            image={<Failure customStyle={'mr-8 md:mr-20'}/>}
            title={"Failed saving your new password"}
            subtitle={"Please try resetting your password again"}
        />

    const ValidationRule = ({text, enabled}) => {
        return (
            <>
                <div className={`flex items-center gap-4`}>
                    <img
                        alt={"checkmarkAsset"}
                        src={checkmarkAsset(enabled)}
                        className={"w-8"}
                    />
                    <div className="text-sm">{text}</div>
                </div>
            </>
        )
    }

    const RuleForPassword = () => {
        const pass = watch('password')
        const passConfirm = watch('passwordConfirm')
        const ruleLength = pass && pass.length > 7;
        const ruleSymbolNum = /(?=.*\d)|(?=.*[@$!%*#?&])/.test(pass);
        const rulePassConfirm = pass && pass.length > 0 && pass === passConfirm
        return (
            <>
                <ValidationRule text='Be at least 8 characters long' enabled={ruleLength}/>
                <div className="mb-2"></div>
                <ValidationRule text='Contain a symbol or number' enabled={ruleSymbolNum}/>
                <div className="mb-2"></div>
                <ValidationRule text='Password and confirmation match' enabled={rulePassConfirm}/>
            </>
        );
    };

    const ResetPasswordForm = () => {
        const errorMessage = errors
            && ((errors.password && errors.password.message) || (errors.passwordConfirm && errors.passwordConfirm.message))
        return (
            <Form onSubmit={handleSubmit(onSubmit)}>
                <PageTitle text="Type your new password"/>
                {errorMessage ? <InputError text={errorMessage} customStyle={"my-8 md:my-12"}/>
                    : <div className="my-12"></div>
                }
                <div className="w-11/12 md:w-full">
                    <InputPassword
                        title={"PASSWORD"}
                        id={"password"}
                        {...register('password')}
                    />
                    <div className="mb-6"></div>
                    <InputPassword
                        title={"VERIFY PASSWORD"}
                        id={"passwordConfirm"}
                        {...register("passwordConfirm")}
                    />
                    <div className="mb-4"></div>
                    {RuleForPassword()}
                </div>
                <Button text="Save Password" customStyle="w-11/12 md:w-full mt-16"/>
            </Form>
        )
    }

    const showForm = ['pending', 'loading'].includes(loadingState)
    const showProgress = loadingState === 'loading'
    const showSuccess = ['success'].includes(loadingState)
    const showFailure = ['failure'].includes(loadingState)

    return (
        <>
            <PageLogo/>
            {showProgress && <ProgressOverlay/>}
            <PageLayout>
                {showForm && ResetPasswordForm()}
                {showSuccess && SuccessfulScreen()}
                {showFailure && FailureScreen()}
            </PageLayout>
        </>
    )
}

export default ResetPasswordPage

import React from 'react';
import {MobileView, BrowserView} from "react-device-detect";
import Logo from "../../components/images/Logo";
import {PageTitle} from "../../components/pages/Page";
import Button from "../../components/buttons/Button";
import {useParams} from "react-router-dom";

const StepTitle = ({text}) => {
    return (
        <div className={`font-bold text-xl md:text-2xl text-center mb-4`}>
            {text}
        </div>
    )
}
const StepText = ({text}) => {
    return (
        <div className={`text-lg text-center mb-4`}>
            {text}
        </div>
    )
}

const MagicLink = ({link}) => {
    return (
        <div className="magic-link" style={{marginBottom: '80px'}}>
            <a href={link}>Your magic link to Momatu home</a>
        </div>
    )
}

const InvitationsPage = () => {

    const token = useParams().token;

    const onDownloadClick = () => {
        const newWindow = window.open('https://momatu.onelink.me/WS4n/download', '_blank')
        if (newWindow) newWindow.opener = null
    }

    return (
        <>
            <div className="flex items-center justify-center flex-col">
                <Logo className="w-32 md:w-48 lg:w-56 mt-12 mb-12 md:mb-20"/>
                <PageTitle text="You have been invited to join a Momatu home" customStyle="ml-2 mr-2 mb-6 md:mb-10"/>
                <StepTitle text="Step 1"/>
                <StepText text="Download the Momatu app"/>
                <Button text={"Download the app"} customStyle={"w-11/12 md:w-80 mb-10"} onClick={onDownloadClick}/>

                <StepTitle text="Step 2"/>

                <BrowserView>
                    <StepText text="Click the magic link on your Mobile Device"/>
                    <MagicLink link={window.location.href}/>
                </BrowserView>

                <MobileView>
                    <StepText text="Come back to this message once signed up and click the magic link below"/>
                    <MagicLink link={`momatu://invitations/${token}`}/>
                </MobileView>
            </div>
        </>
    )
}

export default InvitationsPage

import React from 'react'

const Link = ({text, href, ...otherProps}) => {
    return (
        <a target="_blank"
           rel="noopener noreferrer"
           href={href}
           {...otherProps}>
            {text}
        </a>
    )
}

export default Link

import React from 'react';
import ProgressSpinner from "../images/ProgressSpinner";

const ProgressOverlay = () => {
    return (
        <>
            <div className="absolute inset-0 bg-gray-900 bg-opacity-75"/>
            <ProgressSpinner/>
        </>
    );
};

export default ProgressOverlay;

import React from 'react';
import LockAsset from "../../assets/lock.svg";

const Lock = () => {
    return (
        <img alt="lock" src={LockAsset} className="w-10 md:w-12 mb-6"/>
    )
};

export default Lock;

import axios from "axios";

export const ForgotPasswordHandler = async (email) => {
    const url = `${process.env.REACT_APP_API_URL}user/password/forgot`;
    return axios.post(url, {email: email.toLowerCase()})
        .catch(err => {
            const errorMessage = err.response.data.message
            return {
                errorMessage
            }
        })
}

export const ResetPasswordHandler = async (token, password) => {
    const url = `${process.env.REACT_APP_API_URL}user/password/reset`;
    return axios.post(url, {token, password})
        .catch(err => {
            const errorStatus = err.response.status.toString()
            return {
                errorStatus
            }
        })
}

export const VerifyEmailHandler = async (token) => {
    const url = `${process.env.REACT_APP_API_URL}user/email/verify/${token}`;
    return axios.get(url)
        .catch(err => {
            const errorStatus = err.response.status.toString()
            return {
                errorStatus
            }
        })
}

export const CreateVideoRoomWherebyHandler = async (auth, homeId) => {
    const url = `${process.env.REACT_APP_API_URL}homes/${homeId}/rooms/whereby`;
    return axios.post(url, undefined, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        }
    }).catch(err => {
        const errorStatus = err.response.status.toString()
        return {
            errorStatus
        }
    })
}


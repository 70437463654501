import React, {useState} from 'react'
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {ForgotPasswordHandler} from '../../services/API';

import {PageLogo} from "../../components/images/Logo";
import Lock from '../../components/images/Lock';
import Success from "../../components/images/Success";
import Button from "../../components/buttons/Button";

import ProgressOverlay from "../../components/pages/ProgressOverlay";
import ActionPage from "../../components/pages/ActionPage";
import {PageLayout, PageTitle, PageDescription} from "../../components/pages/Page";

import Form from "../../components/forms/Form";
import Input, {InputError} from "../../components/forms/Input";

const ForgotPasswordPage = () => {
    const schema = yup.object().shape({
        email: yup.string()
            .required("Please Type Password")
            .email("Invalid Email address")
    })

    const [loadingState, setLoadingState] = useState('pending')
    const {register, handleSubmit, formState: {errors}} = useForm({
        mode: "onSubmit",
        resolver: yupResolver(schema),
    });

    const SuccessfulScreen = () =>
        <ActionPage
            image={<Success customStyle={'ml-16 md:ml-24'}/>}
            title={"Requested password reset"}
            subtitle={"Please check your email for instructions on how to set your new password"}
        />

    const onSubmit = async ({email}) => {
        setLoadingState('loading')
        const result = await ForgotPasswordHandler(email);
        setLoadingState(!result.errorMessage ? 'success' : 'failure')
    }

    const ForgotPasswordForm = () => {
        const errorMessage = errors && errors.email && errors.email.message
        return (
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Lock/>
                <PageTitle text="Forgot your password" customStyle="mb-12 md:mb-20"/>
                <div className="w-11/12 md:w-full">
                    <Input
                           title="EMAIL ADDRESS"
                           placeholder="your email address"
                           id="email"
                           {...register("email")}
                    />
                    <div className="mb-48 md:mb-64">
                        {errorMessage && <InputError text={errorMessage} customStyle={'my-4'}/>}
                    </div>
                </div>
                <PageDescription
                    text="We will send you an email with instructions how to reset your password"
                    customStyle="w-11/12 md:w-full mb-4"
                />
                <Button text={"Reset Password"} customStyle={"w-11/12 md:w-full"}/>
            </Form>
        )
    }

    const showForm = ['pending', 'loading'].includes(loadingState)
    const showProgress = loadingState === 'loading'
    const showSuccess = ['success', 'failure'].includes(loadingState)

    return (
        <>
            <PageLogo/>
            {showProgress && <ProgressOverlay/>}
            <PageLayout>
                {showForm && ForgotPasswordForm()}
                {showSuccess && SuccessfulScreen()}
            </PageLayout>
        </>
    )
};

export default ForgotPasswordPage

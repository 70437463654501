import React from 'react'

export const PageLayout = ({children}) => {
    return (
        <div className="flex items-center justify-center flex-col h-screen">
            {children}
        </div>
    )
}

export const PageTitle = ({text, customStyle}) => {
    return (
        <div className={`font-bold text-2xl md:text-4xl text-center ${customStyle}`}>
            {text}
        </div>
    )
}


export const PageDescription = ({text, customStyle}) => {
    return (
        <div className={`text-description text-sm md:text-base text-center mx-auto ${customStyle}`}>
            {text}
        </div>
    )
}

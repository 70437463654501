import React from 'react';
import SpinnerAsset from "../../assets/spinner.gif";

const ProgressSpinner = () => {
    return (
        <img
            alt="Progress"
            src={SpinnerAsset}
            className=" absolute left-1/2 top-1/2
                        transform
                        -translate-x-1/2
                        -translate-y-1/2
                        w-9 md:w-12"
        />
    );
};

export default ProgressSpinner;

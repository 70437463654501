import React from 'react';
import SuccessAsset from "../../assets/success.svg";

const Success = ({customStyle}) => {
    return (
        <img
            alt="success"
            src={SuccessAsset}
            className={`w-48 md:w-80 ${customStyle}`}
        />
    );
};

export default Success;

import React, {useState} from 'react'
import {LandingPageLogo} from '../../components/images/Logo';
import {PageLayout} from "../../components/pages/Page";

const AppEnvironmentSelectPage = () => {
    const [analytics, setAnalytics] = useState(false)
    const [sensitiveInfo, setSensitiveInfo] = useState(false)
    const createEnvLink = link => {
        if (!analytics && !sensitiveInfo) return link
        return `${link}?${new URLSearchParams({
            ...analytics && {analytics: 1},
            ...sensitiveInfo && {showSensitiveInfo: 1}
        })}`
    }
    // hide env selection screen for non dev and qa deployments
    if (!['dev', 'qa'].includes(process.env.REACT_APP_ENV_NAME)) return <div/>

    return (
        <>
            <PageLayout>
                <div className="mb-8 md:mb-12 lg:mb-16">
                    <LandingPageLogo/>
                </div>
                <CheckBox text="Analytics" checked={analytics} setChecked={setAnalytics}/>
                <CheckBox text="Sensitive Info" checked={sensitiveInfo} setChecked={setSensitiveInfo}/>

                <EnvButton text={"Development"} link={createEnvLink("momatu://config/api/stage")}/>
                <EnvButton text={"QA"} link={createEnvLink("momatu://config/api/uat")}/>
                <EnvButton text={"Production"} link={createEnvLink("momatu://config/api/prod")}/>
            </PageLayout>
        </>
    )
};

const CheckBox = ({text, checked, setChecked}) => {
    return (
        <label>
            <input type="checkbox" checked={checked} onChange={() => setChecked(!checked)}/>
            &nbsp;{text}
        </label>
    )
}

const EnvButton = ({text, link}) => {
    return (
        <button
            className={`block mx-auto bg-white-500 hover:bg-white-700 focus:bg-white-700 focus:outline-none 
                text-black-600 font-semibold py-3 border rounded-full cursor-pointer w-11/12 max-w-xs mt-3`}
            onClick={() => window.location = link}
        >
            {text}
        </button>
    )
};

export default AppEnvironmentSelectPage

import React from 'react';

const Button = ({text, customStyle ,...otherProps}) => {
    return (
        <button
            className={`block mx-auto bg-primary-500 hover:bg-primary-700 focus:bg-primary-700
            focus:outline-none text-white-600 font-semibold py-3 border rounded-full cursor-pointer ${customStyle}`}
            {...otherProps}
        >
            {text}
        </button>
    )
};

export default Button;

import React, {useEffect, useState} from 'react'
import {PageLogo} from "../../components/images/Logo";
import {PageLayout} from "../../components/pages/Page";
import ActionPage from "../../components/pages/ActionPage";
import Failure from "../../components/images/Failure";
import EmailVerified from "../../components/images/EmailVerified";
import ProgressOverlay from "../../components/pages/ProgressOverlay";
import {VerifyEmailHandler} from "../../services/API";
import {useParams} from "react-router-dom";

const VerifyEmailPage = () => {
    const token = useParams().token
    const [loadingState, setLoadingState] = useState()

    useEffect(() => {
        async function verifyEmail() {
            setLoadingState('loading')
            const result = await VerifyEmailHandler(token)
            // setLoadingState('success')
            setLoadingState(!result.errorStatus ? 'success' : 'failure')
        }
        verifyEmail()
    }, [token])

    const SuccessfulScreen = () =>
        <ActionPage
            image={<EmailVerified/>}
            title={"Email verified successfully"}
            subtitle={"Now you can go back using the app"}
        />

    const FailureScreen = () =>
        <ActionPage
            image={<Failure customStyle={'mr-8 md:mr-20'}/>}
            title={"Failed verifying your email"}
            subtitle={"Please try again"}
        />

    const showProgress = loadingState === 'loading'
    const showSuccess = ['success'].includes(loadingState)
    const showFailure = ['failure'].includes(loadingState)

    return (
        <>
            <PageLogo/>
            {showProgress && <ProgressOverlay/>}
            <PageLayout>
                {showSuccess && SuccessfulScreen()}
                {showFailure && FailureScreen()}
            </PageLayout>
        </>
    )
}

export default VerifyEmailPage

import React, {useState} from 'react';
import Show from "../images/Show";

const Input = React.forwardRef(({title, placeholder, id, ...otherProps}, ref) => {
    return (
        <>
            <div className="uppercase font-semibold text-sm md:text-base">
                {title}
            </div>
            <input className="border-b-2 py-3 w-full md:w-full outline-none lowercase"
                   placeholder={placeholder}
                   id={id}
                   {...otherProps}
                   ref={ref}
            />
        </>
    );
});

export const InputPassword = React.forwardRef(({title, id, ...otherProps}, ref) => {
    const [show, setShow] = useState(false)
    return (
        <>
            <div className="uppercase font-semibold text-sm md:text-base">
                {title}
            </div>
            <div className="flex justify-between items-center">
                <input className="border-b-2 py-3 w-full md:w-full outline-none"
                       id={id}
                       {...otherProps}
                       ref={ref}
                       type={show ? null : "password"}
                />
                <Show show={show} setShow={setShow}/>
            </div>
        </>
    );
});

export const InputError = ({text, customStyle}) => {
    return (
        <div className={`rounded-2xl bg-red-100 py-3 px-3 text-center w-11/12 md:w-full ${customStyle}`}>
            <span className="text-red-500">{text}</span>
        </div>
    );
};

export default Input;

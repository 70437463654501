import React from 'react';
import hideIcon from '../../assets/hide.svg';
import showIcon from '../../assets/show.svg';

const Show = ({show, setShow}) => {
    return (
        <img
            alt='noHide'
            src={show ? showIcon : hideIcon}
            className="w-6 md:w-8 ml-1"
            onClick={() => setShow(!show)}
        />
    );
};

export default Show;

import React from 'react';
import {PageLayout} from '../../components/pages/Page';
import {LandingPageLogo} from '../../components/images/Logo';
import {PlaystoreButton, AppstoreButton} from '../../components/buttons/Store';
import Link from '../../components/buttons/Link';

const LandingPage = () => {
    return (
        <PageLayout>
            <div className="mb-8 md:mb-12 lg:mb-16">
                <LandingPageLogo/>
            </div>
            <div className="mb-4 md:mb-4 lg:mb-8 flex justify-evenly
                items-center gap-3">
                <PlaystoreButton/>
                <AppstoreButton/>
            </div>
            <div className=" flex justify-center items-center gap-1.5">
                <LandingPageTerms/>
                <span>and</span>
                <LandingPagePrivacy/>
            </div>
        </PageLayout>
    )
}

export const LandingPageTerms = () => {
    return (
        <Link text={"Terms of Service"}
              href={"terms-of-use.html"}
              className="text-xs sm:text-xs md:text-sm lg:text-base underline"
        />
    )
}

export const LandingPagePrivacy = () => {
    return (
        <Link text={"Privacy Policy"}
              href={"privacy-policy.html"}
              className="text-xs sm:text-xs md:text-sm lg:text-base underline"
        />
    )
}

export default LandingPage
